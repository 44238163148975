
import { defineComponent, onMounted, ref, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import { useRouter } from "vue-router";
import store from "@/store";
import { ErrorMessage, Field, Form } from "vee-validate";

export default defineComponent({
  name: "broker-building-house-insurance-claims-settlement-offer",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data() {
    return {
      publicId: this.$route.params.publicId,
      claim: {},
      offerDocument: "",
      amount: "",
      route: "",
    };
  },
  setup() {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const user = computed(() => {
      return store.getters.currentUser;
    });

    const saveSettlementOffer = (values, user, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();
            formData.append("amount", values["amount"]);
            formData.append("claimPublicId", values["claimPublicId"]);
            formData.append(
              "offerDocument",
              values["offerDocument"] as string | Blob
            );

            ApiService.setHeader();
            ApiService.post("/settlement", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then(() => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Settlement offer has been sent",
                  "success"
                );
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name:
                    user.role == "super-admin"
                      ? "admin-client-claims-listing"
                      : "broker-client-claims-listing",
                });
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Settlement Offer", [
        "Claims",
        "House Insurance Claim",
        "Building",
      ]);
    });

    return { submitButton1, saveSettlementOffer, user };
  },
  created() {
    // Get the authenticated user role
    const user = store.getters.currentUser;

    // The the default routes based on the authenticated user role
    this.route =
      user.role == variables.SUPER_ADMIN_USER_ROLE
        ? variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE
        : variables.BROKER_CLIENTS_CLAIMS_ROUTE;

    this.getClaimInformation();

    //Set page title
    document.title =
      "House Insurance Claim: Settlement Offer | " + process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${this.route}/${this.publicId}`)
          .then(({ data }) => {
            //Assign data to variables
            this.claim = data.data;

            if (data.data.isSettlementOfferAccepted) {
              variables.toastAlert(
                "Claim settlement offer has already been accepted",
                "error"
              );
              router.push({
                name: "broker-client-claims-listing",
              });
            }
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
            router.go(-1);
          });
      }
    },
    capitalizeWord(value) {
      return variables.capitalizeWord(value);
    },
    addCommaToNumber(event) {
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return this.$nextTick(() => (event.target.value = newValue));
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    offerDocumentSelected(event) {
      this.offerDocument = event.target.files[0];
    },
  },
});
